import {
    ContextualMenu, DefaultButton, Dropdown, getId,
    Icon, IDragOptions, IDropdownOption, Modal, PrimaryButton, ProgressIndicator, TextField
} from '@fluentui/react';
import * as React from 'react';
import { ShortCustomerModel } from '../../models/customers/CustomerModel';
import { IDataResponseResult, IResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { NewOrderModel } from '../../models/orders/OrderModel';
import { CustomerDropdownItem } from '../../models/others/OtherTypes';
import { OrderService } from '../../services/OrderService';
import { DataUtilities } from '../common/DataUtilities';
import { StyleUtils } from '../common/StyleUtils';
import { Utils } from '../common/Utils';
import { CustomerPicker } from '../customers/CustomerPicker';

interface INewOrderViewProps {
    onAction: (refresh: boolean) => void;
}

interface INewOrderViewState extends IResponseResult {
    model: NewOrderModel;
    isSubmitting: boolean;
}

export default class NewOrderView extends React.Component<INewOrderViewProps, INewOrderViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: INewOrderViewProps) {
        super(props);

        const model = new NewOrderModel();
        model.qty = 1;

        this.state = {
            model: model,
            errorMessages: new NewOrderModel(),
            isSubmitting: false
        };
    }

    public render() {
        const { onAction } = this.props;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <div>
            <Modal
                isOpen={true}
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                onDismiss={() => { onAction(false); }}
                isBlocking={false}
                isModeless={true}
                styles={StyleUtils.getModalWidthStyles(500)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={width10}><b>New Order</b></div>
                            <div className={width2 + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={() => onAction(false)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body">
                    {this.inputForm()}
                </div>
            </Modal>
        </div>;
    }

    private inputForm = () => {
        const { onAction } = this.props;
        const { model, errorMessages, isSubmitting } = this.state;

        const customerOption = new CustomerDropdownItem();
        customerOption.id = model.customerId;
        customerOption.name = model.customer;

        const width6 = Utils.createPanelStyle(6);

        return <div>
            <CustomerPicker onSelect={this.onCustomerSelectChange} />

            <TextField label="Link" value={model.link} errorMessage={errorMessages["Link"]} required multiline rows={2}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                    this.onValueChange("link", newValue)} />

            <Dropdown
                label="Qty"
                selectedKey={model.qty}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
                    this.onValueChange("qty", option ? option.key : undefined)}
                options={DataUtilities.getNumberOptions(false)} />

            <TextField label="Note" value={model.note} multiline rows={2}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                    this.onValueChange("note", newValue)} />

            {!isSubmitting && <div className="ms-Grid ph-0">
                <div className="ms-Grid-row pt-20">
                    <div className={width6}>
                        <DefaultButton onClick={() => onAction(false)}>Cancel</DefaultButton>
                    </div>
                    <div className={width6 + " right"}>
                        <PrimaryButton onClick={this.submit}>Submit</PrimaryButton>
                    </div>
                </div>
            </div>}

            {isSubmitting && <div className="pt-20">
                <ProgressIndicator label="Submitting New Order" />
            </div>}
        </div>;
    }

    private onCustomerSelectChange = (customer?: ShortCustomerModel) => {
        this.onValueChange("customerId", customer != null ? customer.id : 0);
    }

    private onValueChange = (name: string, value?: any) => {
        const model = this.state.model as any;
        model[name] = value;
        this.setState({ model: model });
    }

    private submit = () => {
        const { model } = this.state;

        this.setState({ isSubmitting: true });
        const request = OrderService.create(model);
        DataUtilities.onRequest(request, this.submitResult);
    }

    private submitResult = (result: IDataResponseResult) => {
        if (result.status === ResponseStatus.success) {
            this.props.onAction(true);
        } else {
        }

        this.setState({ ...(result as IResponseResult), isSubmitting: false });
    }
}