import * as React from 'react';
import { Utils } from '../common/Utils';
import { ShortCustomerModel } from '../../models/customers/CustomerModel';

interface ICustomerRowViewProps {
    customer: ShortCustomerModel;
}

interface ICustomerRowViewState { }

export default class CustomerRowView extends React.Component<ICustomerRowViewProps, ICustomerRowViewState> {
    constructor(props: ICustomerRowViewProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { customer } = this.props;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);
        customer.imageUrl = "https://bees.express:9003/Resources/Customers/Default/A.jpg";
        return <div className="ms-Grid-row pv-5">
            <div className={width2}>
                <img src={customer.imageUrl} style={{ width: 42, borderRadius: 50 }} />
            </div>
            <div className={width10 + " pt-5"}>
                <div className="font-bold">{customer.displayName} - {customer.id}</div>
                <div className="gray"><i>{customer.isEmployee ? "employee" : customer.isUser ? "user" : "customer"}</i></div>
            </div>
        </div>;
    }
}