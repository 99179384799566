import * as React from 'react';
import { OrderTrackingInfo } from '../../models/orders/OrderModel';
import { Utils } from '../common/Utils';

interface IOrderTrackingProps {
    trackings: OrderTrackingInfo[];
}

interface IOrderTrackingState { }

export default class OrderTrackingView extends React.Component<IOrderTrackingProps, IOrderTrackingState> {
    private _width: number;
    private _lastWidth: number;

    constructor(props: IOrderTrackingProps) {
        super(props);

        this.state = {};

        this._width = Math.floor(100 / props.trackings.length);
        this._lastWidth = this._width + (100 - this._width * props.trackings.length);
    }

    public render() {
        const { trackings } = this.props;

        const currentIndex = this.getCurrentOrderTrackingIndex(trackings);
        let fillWidth = currentIndex === trackings.length - 1 ? 100 : (this._width * (currentIndex + 0.5));

        return <div className="rs-tracker as-l-container-full-small as-l-container-mobileoverflow" style={{ width: "100%" }}>
            <div className="as-l-container-mobileoverflow-content">
                <div className="rs-tracker-bar">
                    <div className="rs-tracker-fill" style={{ width: fillWidth + "%" }}></div>
                </div>
                <ol className="rs-tracker-status" style={{ paddingInlineStart: 0 }}>
                    {trackings.map(this.printItem)}
                </ol>
            </div>
        </div>;
    }

    private printItem = (item: OrderTrackingInfo, index: number, arr: OrderTrackingInfo[]) => {
        const itemWidth = (index === arr.length - 1 ? this._lastWidth : this._width) + "%";

        return <>
            {item.status !== "In Progress" && <li style={{ width: itemWidth }}>
                <span>
                    <span className="rs-status">{Utils.splitCapitalCase(item.name)}</span>
                    <span className="rs-status-a11y">{item.status}</span>
                </span>
            </li>}

            {item.status === "In Progress" && <li aria-current="step" style={{ width: itemWidth }}>
                <span>
                    <span className="rs-status rs-status-current">{Utils.splitCapitalCase(item.name)}</span>
                    <span className="rs-status-a11y">{item.status}</span>
                </span>
            </li>}
        </>;
    }

    private getCurrentOrderTrackingIndex = (trackings: OrderTrackingInfo[]) => {
        for (let i = 0; i < trackings.length; i++) {
            if (trackings[i].status === "In Progress") return i;
        }

        return trackings.length - 1;
    }
}