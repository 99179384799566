import * as React from 'react';
import { StyleUtils } from '../common/StyleUtils';
import { Authentication } from '../common/Authentication';
import {
    ContextualMenu, DetailsList, DetailsListLayoutMode, DetailsRow, getId,
    Icon, IDetailsRowProps, IDetailsRowStyles, IDragOptions, Modal, SelectionMode
} from '@fluentui/react';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { IGridColumn } from '../grid/Grid';
import { Utils } from '../common/Utils';
import { WarehouseModel } from '../../models/products/WarehouseModel';

interface IWarehouseContainerProps {
    onSelect: (warehouse: WarehouseModel) => void;
    onClose: () => void;
}

interface IWarehouseContainerState {
    userRoles: UserRolesInfo;
}

export default class WarehouseContainer extends
    React.Component<IWarehouseContainerProps, IWarehouseContainerState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: IWarehouseContainerProps) {
        super(props);

        this.state = {
            userRoles: Authentication.getUserRoles()
        };
    }

    render() {
        const { onClose } = this.props;
        const { userRoles } = this.state;

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={onClose}
                isBlocking={false}
                styles={StyleUtils.getModalWidthStyles(500)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={Utils.createPanelStyle(10)}><b>Warehouse List</b></div>
                            <div className={Utils.createPanelStyle(2) + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={onClose} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body" style={{ fontSize: "medium" }}>
                    <DetailsList
                        items={userRoles.warehouses}
                        compact={false}
                        columns={this.getColumns()}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onRenderRow={this.onRenderRow} />
                </div>
            </Modal>
        </>;
    }

    private onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
        const { onSelect } = this.props;
        const customStyles: Partial<IDetailsRowStyles> = {};

        if (!props) return <></>;

        if (props.itemIndex % 2 === 0) {
            customStyles.root = { backgroundColor: "aliceblue" };
        }

        return <div className="pointer" onClick={() => onSelect(props.item)}>
            <DetailsRow
                key={"grid-row-" + props.itemIndex}
                {...props}
                styles={customStyles} />
        </div>;
    };

    private getColumns = (): IGridColumn[] => {
        let list: IGridColumn[] = [];

        let id: IGridColumn = {
            key: "id", name: "Id", fieldName: "id",
            minWidth: 50, maxWidth: 50, isResizable: true, isSelected: true,
            onRender: (item: WarehouseModel) => {
                return <div>{item.id}</div>
            }
        };
        list.push(id);

        let name: IGridColumn = {
            key: "name", name: "Name", fieldName: "name",
            minWidth: 200, isResizable: true, isSelected: true,
            onRender: (item: WarehouseModel) => {
                return <>{item.name}</>
            }
        };
        list.push(name);

        let color: IGridColumn = {
            key: "color", name: "Color", fieldName: "color",
            minWidth: 200, isResizable: false, isSelected: false,
            onRender: (item: WarehouseModel) => {
                return <span className="dot" style={{ backgroundColor: item.color }}></span>
            }
        };
        list.push(color);

        return list;
    }
}