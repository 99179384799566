import * as React from 'react';
import {
    getTheme, Selection, SelectionMode, DetailsListLayoutMode, IDetailsRowProps, IDetailsRowStyles,
    IColumn, DetailsList, DetailsRow, ISelection
} from '@fluentui/react';
import { IGridColumn, RowActionInfo, IconName } from './Grid';
import { PagedListModel, PagedRow } from '../../models/others/PageTypes';
import { Utils } from '../common/Utils';

const theme = getTheme();

export interface IGridDataProps {
    data: PagedListModel<any>;
    columns: IGridColumn[];
    onRowAction: (info: RowActionInfo) => void;
    onSortChange: (sortBy: string) => void;
    onSelectionChange?: (data: PagedRow | PagedRow[]) => void;
    selectionMode?: SelectionMode;
}

export interface IGridDataState {
    selection: ISelection;
}

export class GridData extends React.Component<IGridDataProps, IGridDataState> {
    constructor(props: IGridDataProps) {
        super(props);

        this.state = {
            selection: new Selection({
                onSelectionChanged: () => this.onSelectionChanged(this.state.selection.getSelection())
            })
        };
    }

    render() {
        const { data, columns, selectionMode } = this.props;
        const { selection } = this.state;

        return <>
            <DetailsList
                items={data.items}
                columns={columns as IColumn[]}
                compact={true}
                selectionMode={selectionMode}
                selection={selection}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderRow={this.onRenderRow}
                onItemInvoked={this.onInvoked}
                key={Utils.getRandomKey()}
                styles={{ headerWrapper: {} }}
            />
        </>;
    }

    private onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
        if (!props) {
            return <DetailsRow item={undefined} itemIndex={0} />
        }

        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props.item.BackgroundColor) {
            customStyles.root = { backgroundColor: props.item.BackgroundColor };
        } else if (props.itemIndex % 2 === 0) {
            customStyles.root = { backgroundColor: Utils.altRowColor };
        }

        return <>
            <DetailsRow
                className="pointer"
                key={"grid-row-" + props.itemIndex}
                {...props}
                styles={customStyles} />
        </>;
    };

    private onSelectionChanged = (data: PagedRow | PagedRow[]) => {
        if (this.props.onSelectionChange) this.props.onSelectionChange(data);
    }

    //*******************************************************
    //******************** UI *******************************
    //*******************************************************

    protected onInvoked = (item: PagedRow, index?: number) => {
        if (this.props.onRowAction) {
            const action = new RowActionInfo();
            action.action = IconName.CustomList;
            action.item = item;

            this.props.onRowAction(action);
        }
    }
    //onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn) => void;
    protected onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn) => {
        this.props.onSortChange(column.fieldName ?? "");
    }
}