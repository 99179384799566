import {
    ContextualMenu, getId, Icon, IContextualMenuProps, IDragOptions, Modal, PrimaryButton,
    ProgressIndicator, TextField
} from "@fluentui/react";
import React from "react";
import { IDataResponseResult, ResponseStatus } from "../../models/main/ResponseResult";
import { PhotoCollageInputModel } from "../../models/photoCollages/PhotoCollageModel";
import { AdvertisementService } from "../../services/AdvertisementService";
import { BasicDatePicker } from "../common/BasicDatePicker";
import { DataUtilities } from "../common/DataUtilities";
import { StyleUtils } from "../common/StyleUtils";
import { Utils } from "../common/Utils";

interface IAdvertisementEntryViewProps {
    isEdit: boolean;
    advertisement?: PhotoCollageInputModel;
    onClose: (refresh: boolean) => void;
}

interface IAdvertisementEntryViewState {
    model: PhotoCollageInputModel;
    isSubmitting: boolean;
}

export default class AdvertisementEntryView
    extends React.Component<IAdvertisementEntryViewProps, IAdvertisementEntryViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };
    private _commandMenuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: []
    };

    constructor(props: IAdvertisementEntryViewProps) {
        super(props);

        this.state = {
            model: this.initializeModel(props.advertisement),
            isSubmitting: false
        };
    }

    private initializeModel = (advertisement?: PhotoCollageInputModel) => {
        let model = new PhotoCollageInputModel();

        if (advertisement) {
            model.brand = advertisement.brand;
            model.title = advertisement.title;
            model.description = advertisement.description;
            model.url = advertisement.url;
            model.id = advertisement.id;
            model.start = new Date(advertisement.start);
            model.end = new Date(advertisement.end);
        }

        return model;
    }

    render() {
        const { onClose } = this.props;
        const { model, isSubmitting } = this.state;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={() => onClose(false)}
                isBlocking={true}
                isModeless={false}
                styles={StyleUtils.getModalWidthStyles(500)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={width10}><b>Edit Advertisement</b></div>
                            <div className={width2 + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={() => onClose(false)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body">
                    <div>
                        <TextField label="Brand" value={model.brand}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                                this.onValueChange("brand", newValue)} />
                    </div>

                    <div className="pt-10">
                        <TextField label="Title" value={model.title}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                                this.onValueChange("title", newValue)} />
                    </div>

                    <div className="pt-10">
                        <TextField label="Description" value={model.description}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                                this.onValueChange("description", newValue)} />
                    </div>

                    <div className="pt-10">
                        <TextField label="URL" value={model.url}
                            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                                this.onValueChange("url", newValue)} />
                    </div>

                    <div className="pt-10">
                        <BasicDatePicker args={{
                            label: "Start Date",
                            onSelectDate: (date: Date | null | undefined) => this.onValueChange("start", date),
                            value: model.start
                        }} />
                    </div>

                    <div className="pt-10">
                        <BasicDatePicker args={{
                            label: "End Date",
                            onSelectDate: (date: Date | null | undefined) => this.onValueChange("end", date),
                            value: model.end
                        }} />
                    </div>

                    {!isSubmitting && <div className="ms-Grid-row center pv-20">
                        <PrimaryButton onClick={this.onSubmit}>Submit</PrimaryButton>
                    </div>}

                    {isSubmitting && <div className="pv-20">
                        <ProgressIndicator label="Submitting" description="" />
                    </div>}
                </div>
            </Modal>
        </>;
    }

    private onSubmit = () => {
        const { onClose, isEdit } = this.props;
        const { model } = this.state;
        let request = null;

        if (isEdit) {
            request = AdvertisementService.edit(model.id, model);
        } else {
            request = AdvertisementService.create(model);
        }

        const onResult = (response: IDataResponseResult) => {
            if (response.status === ResponseStatus.success) {
                onClose(true);
            }

            this.setState({ isSubmitting: false });
        }

        this.setState({ isSubmitting: true });
        DataUtilities.onRequest(request, onResult);
    }

    private onValueChange = (name: string, value: any) => {
        let model = this.state.model as any;
        model[name] = value;
        this.setState({ model: model });
    }
}