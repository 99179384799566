import * as React from 'react';
import {
    Checkbox, ContextualMenu, DefaultButton, Dropdown, getId, Icon, IDragOptions,
    IDropdownOption, Modal, PrimaryButton
} from '@fluentui/react';
import { OrderFilterModel } from "../../models/orders/OrderModel";
import { Utils } from '../common/Utils';
import { StyleUtils } from '../common/StyleUtils';
import { OrderUtilities } from './OrderUtilities';

interface IOrderFilterViewProps {
    filter: OrderFilterModel;
    onFilter: (filter: OrderFilterModel) => void;
    onClose: () => void;
}

interface IOrderFilterViewState {
    filter: OrderFilterModel;
}

export default class OrderFilterView extends React.Component<IOrderFilterViewProps, IOrderFilterViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: IOrderFilterViewProps) {
        super(props);

        this.state = {
            filter: this.initializeModel(props.filter)
        }
    }

    private initializeModel = (filter: OrderFilterModel) => {
        var newFilter = new OrderFilterModel();
        newFilter.customer = filter.customer;
        newFilter.customerId = filter.customerId;
        newFilter.endDate = filter.endDate;
        newFilter.isAllWarehouses = filter.isAllWarehouses;
        newFilter.isOpen = filter.isOpen;
        newFilter.isUnInvoiced = filter.isUnInvoiced;
        newFilter.pageNumber = filter.pageNumber;
        newFilter.pageSize = filter.pageSize;
        newFilter.pagingDisabled = filter.pagingDisabled;
        newFilter.reason = filter.reason;
        newFilter.search = filter.search;
        newFilter.sortModel = filter.sortModel;
        newFilter.startDate = filter.startDate;
        newFilter.status = filter.status;
        newFilter.tracking = filter.tracking;
        newFilter.warehouseId = filter.warehouseId;

        return newFilter;
    }

    render() {
        const { onClose, onFilter } = this.props;
        const { filter } = this.state;

        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={onClose}
                isBlocking={true}
                isModeless={false}
                styles={StyleUtils.getModalWidthStyles(500)}
            >
                <div>
                    <div className="popup popup-header">
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className={width10}><b>Order Sort & Filters</b></div>
                                <div className={width2 + " right"}>
                                    <Icon iconName="ChromeClose" className="pointer" onClick={onClose} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={this._subtitleId} className="popup-body p-20">
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <Dropdown
                                    label="Sort By"
                                    selectedKey={filter.sortModel.key}
                                    onChange={this.onSortChange}
                                    options={OrderUtilities.getSortOptions()} />
                            </div>
                            <div className="ms-Grid-row pt-15">
                                <Dropdown
                                    label="Reject Reason"
                                    selectedKey={filter.reason}
                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
                                        this.onValueChange("reason", option ? option.key : undefined)}
                                    options={OrderUtilities.getReasonOptions()} />
                            </div>
                            <div className="ms-Grid-row pt-15">
                                <Checkbox label="All Warehouse?" checked={filter.isAllWarehouses} disabled={true}
                                    onChange={(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) =>
                                        this.onValueChange("isAllWarehouses", checked ?? false)} />
                            </div>
                            <div className="ms-Grid-row center pt-15">
                                <span className="pr-20"><DefaultButton onClick={onClose}>Cancel</DefaultButton></span>
                                <PrimaryButton onClick={() => { onFilter(filter) }}>Submit</PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>;
    }

    private onValueChange = (name: string, value: any) => {
        const filter = this.state.filter as any;
        filter[name] = value;
        filter.pageNumber = 1;

        this.setState({ filter: filter });
    }

    private onSortChange = (event: React.FormEvent<HTMLDivElement>, option?: any) => {
        const { filter } = this.state;

        if (!option) return;
        if (filter.sortModel.name === option.key) return;

        filter.sortModel.key = option.key;
        filter.sortModel.name = option.name;
        filter.sortModel.text = option.text;
        filter.sortModel.direction = option.direction;
        filter.pageNumber = 1;

        this.setState({ filter: filter });
    }
}