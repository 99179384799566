import { Icon, SearchBox, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import { Authentication } from '../common/Authentication';
import { DataUtilities, onResponseResultAction } from '../common/DataUtilities';
import { Utils } from '../common/Utils';
import {
    IGridColumn, IGridDataService, onColumnClick, onRowAction
} from '../grid/Grid';
import { GridActionInfo, GridUtilities } from '../grid/GridUtilities';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { CustomerFilterModel, CustomerFilterType, ShortCustomerModel } from '../../models/customers/CustomerModel';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { KeyValueModel, OptionModel } from '../../models/others/OtherTypes';
import { PagedRow, PageFilterModel, SortDirection } from '../../models/others/PageTypes';
import { CustomerService } from '../../services/CustomerService';
import { MyTooltip } from '../common/MyTooltip';
import OptionPicker from '../common/OptionPicker';
import { Page } from '../grid/Page';
import { Pager } from '../grid/Pager';
import CustomerGridDataView from './CustomerGridDataView';

class CustomerDataService implements IGridDataService {
    public sortColumns = [];

    public get(model: PageFilterModel, onResult: onResponseResultAction) {
        let request = CustomerService.search(model as CustomerFilterModel);
        DataUtilities.onRequest(request, onResult);
    }

    public getColumns(onColumnClick: onColumnClick, onRowAction: onRowAction): IGridColumn[] {
        let list: IGridColumn[] = GridUtilities.createColumns(
            new ShortCustomerModel(),
            this.createOnRenders(),
            onColumnClick,
            ["imageUrl", "id"],
            [],
            { "imageUrl": 80, "id": 300 },
            {},
            this.sortColumns
        );

        list = list.concat(this.createCommandColumns(onRowAction));

        return list;
    }

    private createOnRenders(): any {
        return {
            imageUrl: (item: ShortCustomerModel) => <span className="active"></span>,
            id: (item: ShortCustomerModel) => <span></span>
        };
    }

    private createCommandColumns(onRowAction: onRowAction): IGridColumn[] {
        return GridUtilities.createRowActionIcons(this.getActions(), onRowAction);
    }

    public getActions() {
        var actions: GridActionInfo[] = [];
        return actions;
    }

    public getMobileUIRow(item: PagedRow): JSX.Element {
        return <></>;
    }
}

interface ICustomerViewProps {
    onSelect?: (customer: ShortCustomerModel) => void;
}

interface ICustomerViewState {
    userRoles: UserRolesInfo;
    model: CustomerFilterModel;
    customers: ShortCustomerModel[];
    totalCount: number;
    typeOptions: OptionModel[];
    isLoading: boolean;
    listHash: number;
    pageHash: number;
    filterHash: number;
    selectedId: number;
}

export default class CustomerView extends React.Component<ICustomerViewProps, ICustomerViewState> {
    private _service: CustomerDataService = new CustomerDataService();
    private _searchBox: JSX.Element;

    constructor(props: ICustomerViewProps) {
        super(props);

        const model = new CustomerFilterModel();
        model.pageSize = 50;
        model.sortModel.direction = SortDirection.ascending;
        model.filterType = CustomerFilterType.all;

        this.state = {
            userRoles: Authentication.getUserRoles(),
            model: model,
            customers: [],
            totalCount: 0,
            typeOptions: this.initializeTypeOptions(),
            isLoading: false,
            listHash: -1,
            pageHash: -1,
            filterHash: -1,
            selectedId: -1
        };

        this._searchBox = this.createSearchBoxElement("");
    }

    private getCountByTypes = () => {
        const onResult = (result: IDataResponseResult) => {
            if (result.status !== ResponseStatus.fail) {
                const counts = result.data as KeyValueModel[];

                if (counts) {
                    const { typeOptions } = this.state;
                    for (let i = 0; i < typeOptions.length; i++) {
                        const option = typeOptions[i];
                        const status = option.key;
                        const strStatus = status.toString();
                        const count = counts.find((item: KeyValueModel) => item.key === strStatus);

                        option.count = count ? count.value : 0;
                    }

                    this.setState({ typeOptions: typeOptions });
                }
            }
        }

        const { model } = this.state;
        const request = CustomerService.getCountByTypes(model);
        DataUtilities.onRequest(request, onResult);
    }

    private initializeTypeOptions = () => {
        const option1 = new OptionModel();
        option1.key = CustomerFilterType.all;
        option1.text = "All";
        option1.current = true;
        option1.count = 0;

        const option2 = new OptionModel();
        option2.key = CustomerFilterType.openSaleOnly;
        option2.text = "Open Sales";
        option2.current = false;
        option2.count = 0;

        const option3 = new OptionModel();
        option3.key = CustomerFilterType.invoiceAvailable;
        option3.text = "New Invoices";
        option3.current = false;
        option3.count = 0;

        return [option1, option2, option3];
    }

    componentDidMount() {
        this.getList();
    }

    render() {
        const { onSelect } = this.props;
        const { model, customers, totalCount, isLoading, listHash, pageHash } = this.state;

        return <div className="ms-Grid">
            {this.headerElement()}

            <div className="ms-Grid-row">
                {isLoading && <Spinner size={SpinnerSize.large} />}

                <CustomerGridDataView
                    customers={customers}
                    key={listHash}
                    onSelect={(customer: ShortCustomerModel) => {
                        if (onSelect) onSelect(customer);
                    }} />
            </div>

            <div className="ms-Grid-row">
                <Pager
                    totalCount={totalCount}
                    onChange={this.onPageChange}
                    pageSize={model.pageSize}
                    key={pageHash} />
            </div>
        </div>;
    }

    private headerElement = () => {
        const { model, typeOptions } = this.state;

        const width1 = Utils.createPanelStyle(1);
        const width11 = Utils.createPanelStyle(11);

        return <>
            <div className="ms-Grid-row">
                <div className={width11}>
                    <SearchBox
                        autoFocus={false}
                        value={model.search}
                        placeholder="Search"
                        onSearch={(value: string) => this.onValueChange("search", value)}
                        onClear={() => this.onValueChange("search", "")}
                        className="searchbox"
                        styles={{ root: { width: "100%" } }}
                    />
                </div>
                <div className={width1}>
                    <MyTooltip
                        element={<Icon iconName="Refresh" onClick={() => this.getList(false, false)} className="icon" />}
                        text="Refresh" />
                </div>
            </div>

            <div className="ms-Grid-row pv-20 ph-8">
                <OptionPicker options={typeOptions} onChange={this.typeChange} />
            </div>
        </>;
    }

    private createSearchBoxElement = (defaultTex: string): JSX.Element => {
        return <SearchBox
            autoFocus={false}
            value={defaultTex}
            placeholder="Search"
            onSearch={(value: string) => this.onValueChange("search", value)}
            onClear={() => this.onValueChange("search", "")}
            className="searchbox"
            styles={{ root: { width: "100%" } }}
        />
    }

    private onPageChange = (page: Page) => {
        if (page) {
            let { model } = this.state;

            model.pageNumber = page.pageNumber;
            if (model.pageSize !== page.pageSize) model.pageNumber = 1;
            model.pageSize = page.pageSize;

            this.setState({ model: model }, () => this.getList(false, true));
        }
    }

    private onValueChange = (name: string, value: any) => {
        const model = this.state.model as any;
        model[name] = value;
        model.pageNumber = 1;
        this.setState({ model: model }, this.getList);
    }

    private typeChange = (type: CustomerFilterType) => {
        const { model } = this.state;
        model.filterType = type;
        model.pageNumber = 1;
        this.setState({ model: model }, () => this.getList(false));
    }

    private clearFilters = () => {
        const { model } = this.state;
        model.search = undefined;
        model.filterType = CustomerFilterType.all;
        model.pageNumber = 1;
        this.setState({ model: model, filterHash: Utils.getRandomKey() }, this.getList);
    }

    private getList = (getCount = true, pageChange = false) => {
        const { model } = this.state;

        if (!model.filterType) model.filterType = CustomerFilterType.all;

        this.setState({ customers: [], isLoading: true, listHash: Utils.getRandomKey() });

        const request = CustomerService.search(model);
        DataUtilities.onRequest(request, (result: IDataResponseResult) => this.getListResult(result, pageChange));

        if (getCount) this.getCountByTypes();
    }

    private getListResult = (result: IDataResponseResult, pageChange = false) => {
        if (result.status === ResponseStatus.success) {
            this.setState({
                customers: result.data.items,
                listHash: Utils.getRandomKey(),
                totalCount: result.data.totalCount
            });

            if (!pageChange) { this.setState({ pageHash: Utils.getRandomKey() + 100 }); }

            this.setState({ isLoading: false });
        }
    }
}