import { ActionButton, SearchBox, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { IDataResponseResult, ResponseStatus } from "../../models/main/ResponseResult";
import { PhotoCollageFilterModel, PhotoCollageModel } from "../../models/photoCollages/PhotoCollageModel";
import { EditPictureModel } from "../../models/products/PictureModel";
import { AdvertisementService } from "../../services/AdvertisementService";
import { DataUtilities, onResponseResultAction } from "../common/DataUtilities";
import { Utils } from "../common/Utils";
import { Page } from "../grid/Page";
import { Pager } from "../grid/Pager";
import { IPictureService, onGetPicturesCallback } from "../pictures/ManagerPictureView";
import AdvertisementDetailView from "./AdvertisementDetailView";
import AdvertisementEntryView from "./AdvertisementEntryView";
import AdvertisementGridDataView from "./AdvertisementGridDataView";
export class AdvertisementPictureService implements IPictureService {
    public getList(ownerId: number, callback: onGetPicturesCallback): void {
        const onResult: onResponseResultAction = ((result: IDataResponseResult) => {
            if (result.status !== ResponseStatus.fail && callback) callback(result.data);
        });

        let request = AdvertisementService.getPictures(ownerId);
        DataUtilities.onRequest(request, onResult);
    }

    public delete(ownerId: number, id: number): JQueryPromise<any> {
        return AdvertisementService.deletePicture(ownerId, id);
    }

    public create(ownerId: number, model: FormData): JQueryPromise<any> {
        return Utils.postFormData(model, AdvertisementService.RouteCreatePictures(ownerId));
    }

    public edit(ownerId: number, picture: EditPictureModel): JQueryPromise<any> {
        return AdvertisementService.editPicture(ownerId, picture);
    }

    public editList(ownerId: number, pictures: EditPictureModel[]): JQueryPromise<any> {
        return AdvertisementService.editPictures(ownerId, pictures);
    }
}

interface IAdvertisementViewProps { }

interface IAdvertisementViewState {
    filter: PhotoCollageFilterModel;
    list: PhotoCollageModel[];
    totalCount: number;
    selectedItem?: PhotoCollageModel;
    isLoading: boolean;
    listHash: number;
    pageHash: number;
    filterHash: number;
    showEntry: boolean;
    showDetail: boolean;
    showFilter: boolean;
    isEdit: boolean;
}

export default class AdvertisementView extends React.Component<IAdvertisementViewProps, IAdvertisementViewState> {
    private _pictureService: AdvertisementPictureService;

    constructor(props: IAdvertisementViewProps) {
        super(props);

        this.state = {
            filter: new PhotoCollageFilterModel(),
            list: [],
            totalCount: 0,
            isLoading: false,
            listHash: -1,
            pageHash: -1,
            filterHash: -1,
            showEntry: false,
            showDetail: false,
            showFilter: false,
            isEdit: false
        };

        this._pictureService = new AdvertisementPictureService();
    }

    componentDidMount() {
        this.getList();
    }

    render() {
        const {
            filter, list, totalCount, selectedItem, isEdit, isLoading, showEntry, showDetail, listHash, pageHash
        } = this.state;

        const width12 = Utils.createPanelStyle(12);

        return <div className="ms-Grid">
            <div className="ms-Grid-row pt-20">
                <div className={width12 + " center"}>
                    {this.actionElements()}
                </div>
            </div>

            <div className="ms-Grid-row pv-20">
                {isLoading && <Spinner size={SpinnerSize.large} />}

                <AdvertisementGridDataView
                    list={list}
                    key={listHash}
                    onEdit={this.onEdit}
                    onDetail={this.onDetail}
                    onCompleted={this.onDetailClose} />
            </div>

            <div className="ms-Grid-row" style={{ width: "100%", display: "inline-block" }}>
                <Pager
                    totalCount={totalCount}
                    onChange={this.onPageChange}
                    pageSize={filter.pageSize}
                    key={pageHash} />
            </div>

            {showEntry && <>
                <AdvertisementEntryView
                    isEdit={isEdit}
                    advertisement={selectedItem}
                    onClose={this.onEntryClose} />
            </>}

            {showDetail && selectedItem && <>
                <AdvertisementDetailView
                    id={selectedItem.id}
                    pictureService={this._pictureService}
                    onClose={this.onDetailClose} />
            </>}
        </div>;
    }

    private actionElements = () => {
        const { filter } = this.state;

        return <div>
            <SearchBox
                autoFocus={false}
                value={filter.search}
                placeholder="Search"
                onSearch={(value: string) => this.onValueChange("search", value)}
                onClear={() => this.onValueChange("search", "")}
                className="searchbox"
                styles={{ root: { width: "400px", display: "inline-flex" } }}
            />

            <ActionButton
                iconProps={{ iconName: "Refresh" }}
                onClick={() => this.getList()}>Refresh</ActionButton>

            <ActionButton
                iconProps={{ iconName: "Add" }}
                onClick={this.onAdd}>Add New</ActionButton>

            <ActionButton
                iconProps={{ iconName: "Filter" }}
                onClick={() => this.setState({ showFilter: true })}>Filter</ActionButton>
        </div>;
    }

    private getList = (pageChange = false) => {
        const { filter } = this.state;

        this.setState({ list: [], isLoading: true, listHash: Utils.getRandomKey() });

        const request = AdvertisementService.search(filter);
        DataUtilities.onRequest(request, (result: IDataResponseResult) => this.getListResult(result, pageChange));
    }

    private getListResult = (result: IDataResponseResult, pageChange = false) => {
        if (result.status === ResponseStatus.success) {
            this.setState({
                list: result.data.items,
                listHash: Utils.getRandomKey(),
                totalCount: result.data.totalCount
            });

            if (!pageChange) { this.setState({ pageHash: Utils.getRandomKey() + 100 }); }

            this.setState({ isLoading: false });
        }
    }

    private onValueChange = (name: string, value: any) => {
        const filter = this.state.filter as any;
        filter[name] = value;
        filter.pageNumber = 1;

        this.setState({ filter: filter }, this.getList);
    }

    private onFilterChange = (newFilter: PhotoCollageFilterModel) => {
        this.setState({ filter: newFilter, showFilter: false }, this.getList);
    }

    private onFilterClose = () => {
        this.setState({ showFilter: false });
    }

    private onPageChange = (page: Page) => {
        if (page) {
            let { filter } = this.state;

            filter.pageNumber = page.pageNumber;
            if (filter.pageSize !== page.pageSize) filter.pageNumber = 1;
            filter.pageSize = page.pageSize;

            this.setState({ filter: filter }, () => this.getList(true));
        }
    }

    private onAdd = () => {
        this.setState({ showEntry: true, isEdit: false });
    }

    private onEdit = (advertisement: PhotoCollageModel) => {
        this.setState({ selectedItem: advertisement, showEntry: true, isEdit: true });
    }

    private onEntryClose = () => {
        this.setState({ showEntry: false, isEdit: false });
    }

    private onDetail = (advertisement: PhotoCollageModel) => {
        this.setState({ selectedItem: advertisement, showDetail: true });
    }

    private onDetailClose = () => {
        if (window.confirm("Would you like to refresh the data?")) {
            this.getList();
        }

        this.setState({ showDetail: false });
    }
}