import * as React from 'react';
import {
    ContextualMenu, DefaultButton, DocumentCardImage, getId, Icon, IDragOptions,
    ImageFit, Modal
} from '@fluentui/react';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { ChangeWebsiteOrderModel, OrderModel, OrderStatus } from "../../models/orders/OrderModel";
import { Authentication } from '../common/Authentication';
import { Utils } from '../common/Utils';
import { ProductUtilities } from '../products/ProductUtilities';
import EditOrderPriceView from './EditOrderPriceView';
import ApproveOrderView from './ApproveOrderView';
import { OrderService } from '../../services/OrderService';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { DataUtilities } from '../common/DataUtilities';
import { StyleUtils } from '../common/StyleUtils';
import AddPictureView from '../pictures/AddPictureView';
import { apiBaseUrl } from '../../constants/url.constant';
import ChangeOrderWarehouseView from './ChangeOrderWarehouseView';
import { ChangeOrderWebsiteView } from './ChangeOrderWebsiteView';
import EditOrderProductView from './EditOrderProductView';
import { ShortWebsiteModel } from '../../models/products/WebsiteModel';
import { UploadPictureModel } from '../../models/products/PictureModel';

interface IOrderDetailViewProps {
    id: number;
    onCompleted: () => void;
    websiteOptions: ShortWebsiteModel[];
}

interface IOrderDetailViewState {
    order?: OrderModel;
    isSubmitting: boolean;
    userRoles: UserRolesInfo;
    showPicture: boolean;
}

export default class OrderDetailView extends React.Component<IOrderDetailViewProps, IOrderDetailViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: IOrderDetailViewProps) {
        super(props);

        this.state = {
            isSubmitting: false,
            userRoles: Authentication.getUserRoles(),
            showPicture: false
        }
    }

    componentDidMount() {
        this.get();
    }

    render() {
        const { onCompleted } = this.props;
        const { order, showPicture } = this.state;

        if (!order || order.id <= 0) return <></>;

        const width2 = Utils.createPanelStyle(2, 3, 3);
        const width5 = Utils.createPanelStyle(5, 12, 12);
        const width7 = Utils.createPanelStyle(7, 12, 12);
        const width8 = Utils.createPanelStyle(8, 6, 6);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={onCompleted}
                isBlocking={false}
                isModeless={false}
                styles={StyleUtils.getModalWidthStyles(1200)}
            >
                <div>
                    <div className="popup popup-header">
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className={width10}><b>Order Details</b></div>
                                <div className={width2 + " right"}>
                                    <Icon iconName="ChromeClose" className="pointer" onClick={onCompleted} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={this._subtitleId} className="popup-body p-20">
                        <div className="ms-Grid">
                            <div className="ms-Grid-row font-bold p-10" style={{ backgroundColor: "aliceblue", fontSize: "medium" }}>
                                <div className={width2}>{Utils.splitCapitalText(OrderStatus[order.status])}</div>
                                <div className={width8 + " center"}>{order.customer} - {order.id}</div>
                                <div className={width2 + " right"}>{Utils.splitCapitalText(OrderStatus[order.status])}</div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className={width7 + " mt-20"}>
                                    {this.leftElement()}
                                </div>
                                <div className={width5 + " mt-20 pl-20 pr-0 ms-Grid-col-right"}>
                                    {this.rightElement()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {showPicture && <>
                <AddPictureView
                    ownerId={order.id}
                    onSubmit={this.addPicture}
                    onClose={() => this.setState({ showPicture: false })} />
            </>}
        </>;
    }

    private leftElement = () => {
        const { order } = this.state;

        if (!order || order.id <= 0) return <></>;

        const width5 = Utils.createPanelStyle(5, 12, 12);
        const width7 = Utils.createPanelStyle(7, 12, 12);

        return <>
            <div className="box ms-Grid-row p-15">
                <div className={width5}>
                    {this.pictureElement()}

                    <div className="p-20 center">
                        <DefaultButton onClick={() => this.setState({ showPicture: true })}>Select Photo</DefaultButton>
                    </div>
                </div>
                <div className={width7 + " ph-0"}>
                    <EditOrderProductView onCompleted={this.get} item={order} />
                </div>
            </div>
            <div className="ms-Grid-row">
                <EditOrderPriceView onCompleted={this.get} item={order} />
            </div>
        </>;
    }

    private rightElement = () => {
        const { websiteOptions } = this.props;
        const { order } = this.state;

        if (!order || order.id <= 0) return <></>;

        return <>
            <div className="box p-15">
                <ChangeOrderWarehouseView onCompleted={this.get} item={order} />
            </div>
            <div className="box mt-20 p-15">
                <ChangeOrderWebsiteView
                    defaultModel={this.getChangeWebsiteModel()}
                    onCompleted={this.get}
                    options={websiteOptions} />
            </div>
            <div className="box mt-20 p-15">
                <ApproveOrderView onCompleted={this.get} item={order} />
            </div>
        </>;
    }

    private pictureElement = () => {
        const { order } = this.state;
        const url = order && order.product ? ProductUtilities.getProductImageUrl(order.product.picture) : "";

        var size = Utils.pictureFileSizes["Feature"];
        if (!size || !size.height) size = { width: 200, height: 200 };

        if (Utils.isNullOrEmpty(url)) return <></>;

        return <>
            <DocumentCardImage
                height={size.height}
                imageFit={ImageFit.centerCover}
                className="product-picture image-radius"
                imageSrc={url} />
        </>
    }

    private get = () => {
        const { id } = this.props;
        const request = OrderService.get(id);
        const onResult = (result: IDataResponseResult) => {
            if (result.status === ResponseStatus.success) {
                this.setState({ order: result.data });
            }
        }

        DataUtilities.onRequest(request, onResult);
    }

    private addPicture = (picture: UploadPictureModel) => {
        const { id } = this.props;

        const onResult = (result: IDataResponseResult) => {
            if (result.status === ResponseStatus.success) {
                this.setState({ showPicture: false });
                this.get();
            }
        }

        let requestForm = new FormData();
        Utils.buildFormDataProperties(requestForm, picture, [], "");

        const request = Utils.postFormData(requestForm, apiBaseUrl + OrderService.RouteCreatePicture(id));
        DataUtilities.onRequest(request, onResult);
    }

    private getChangeWebsiteModel = () => {
        const { order } = this.state;

        const model = new ChangeWebsiteOrderModel();

        if (order) {
            model.id = order.id;
            model.websiteId = order.product.website?.id;
            model.earningPointRate = order.earningPointRate;
        }

        return model;
    }
}