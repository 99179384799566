import { IRectangle, List } from '@fluentui/react';
import * as React from 'react';
import { StyleUtils } from '../common/StyleUtils';
import { OrderModel } from '../../models/orders/OrderModel';
import OrderItemView from './OrderItemView';

interface IOrderGridDataViewProps {
    orders: OrderModel[];
    onDetail: (id: number) => void;
    onCompleted: () => void;
}

interface IOrderGridDataViewState { }

export default class OrderGridDataView extends React.Component<IOrderGridDataViewProps, IOrderGridDataViewState> {
    private _columnCount: number = 0;

    constructor(props: IOrderGridDataViewProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { orders } = this.props;

        return <div>
            <List
                items={orders}
                onRenderCell={this.onRenderCell}
                getItemCountForPage={this.getItemCountForPage} />
        </div>;
    }

    private getItemCountForPage = (itemIndex?: number, surfaceRect?: IRectangle): number => {
        if (itemIndex === 0) {
            if (surfaceRect != null && surfaceRect.width < 500) this._columnCount = 1;
            else if (surfaceRect != null && surfaceRect.width < 1000) this._columnCount = 2;
            else this._columnCount = 3;
        }
        return this.props.orders.length;
    }

    private onRenderCell = (order?: OrderModel, index?: number, isScrolling?: boolean) => {
        const width = (100 / this._columnCount) + '%';
        const height = this._columnCount === 1 ? "auto" : 670;

        if (order == null) return <>empty</>;

        return <div key={order.id + "-wrapper"} className="list-grid-tile" data-is-focusable={true}
            style={StyleUtils.getProductItemStyles(width, height)}>

            <OrderItemView
                key={order.id}
                order={order}
                onDetail={this.props.onDetail}
                onCompleted={this.props.onCompleted} />
        </div>;
    }
}