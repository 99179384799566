import * as React from 'react';
import { TextField, IDropdownOption, Dropdown } from '@fluentui/react';
import { AccountPhoneModel } from '../../models/accounts/AccountTypes';
import { Utils } from '../common/Utils';
import cambodiaLogo from "../../assets/images/Cambodia.jpg";
import usaLogo from "../../assets/images/USA.jpg";

interface IPhoneViewProps {
    showTelegram: boolean;
    defaultModel?: AccountPhoneModel;
    isRequired?: boolean;
    errorMessages: AccountPhoneModel;
    onChange: (model: AccountPhoneModel) => void;
}

interface IPhoneViewState {
    model: AccountPhoneModel;
}

export class PhoneView extends React.Component<IPhoneViewProps, IPhoneViewState> {
    private countryCodeOptions: IDropdownOption[] = [
        { key: '855', text: '(+855)', data: { icon: cambodiaLogo } },
        { key: '1', text: '(+1)', data: { icon: usaLogo } }
    ];

    constructor(props: IPhoneViewProps) {
        super(props);

        const model = new AccountPhoneModel();

        if (props.defaultModel) {
            model.countryCode = props.defaultModel.countryCode;
            model.phoneNo = props.defaultModel.phoneNo;
            model.telegram = props.defaultModel.telegram;
        }

        this.state = {
            model: model
        }
    }

    public render() {
        const { errorMessages, isRequired } = this.props;
        const { model } = this.state;
        const width5 = Utils.createPanelStyle(5);
        const width7 = Utils.createPanelStyle(7);

        return <div className="ms-Grid-row">
            <div className={width5}>
                <Dropdown
                    label="Country Code"
                    defaultSelectedKey={model.countryCode}
                    options={this.countryCodeOptions}
                    onRenderTitle={this.onRenderTitle}
                    onRenderOption={this.onRenderOption}
                    required={isRequired}
                    onChange={this.onCountryChange}
                />
            </div>
            <div className={width7}>
                <TextField label="Phone #" value={model.phoneNo} required={isRequired}
                    onChange={this.onPhoneNoChange}
                    errorMessage={errorMessages.phoneNo} />
            </div>
        </div>;
    }

    private onRenderTitle = (selectedOptions?: IDropdownOption[]) => {
        if (!selectedOptions || selectedOptions.length === 0) return null;

        const selectedOption = selectedOptions[0];

        return <>{this.displayValue(selectedOption)}</>;
    };

    private onRenderOption = (option?: IDropdownOption) => {
        if (!option) return null;

        return <>{this.displayValue(option)}</>;
    };

    private displayValue = (option: IDropdownOption) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            {option.data?.icon && (
                <img src={option.data.icon} alt="" style={{ width: 25, height: 20, marginRight: 8 }} />
            )}
            <div>{option.text}</div>
        </div>;
    }

    private onCountryChange = (ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const { model } = this.state;
        model.countryCode = option ? option.key.toString() : "";
        this.setState({ model: model });
        this.props.onChange(model);
    }

    private onPhoneNoChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const { model } = this.state;
        model.phoneNo = value ?? "";
        this.setState({ model: model });
        this.props.onChange(model);
    }
}