import { List } from '@fluentui/react';
import * as React from 'react';
import { ShortCustomerModel } from '../../models/customers/CustomerModel';
import CustomerRowView from './CustomerRowView';

interface ICustomerGridDataViewProps {
    customers: ShortCustomerModel[];
    onSelect: (customer: ShortCustomerModel) => void;
}

interface ICustomerGridDataViewState { }

export default class CustomerGridDataView extends React.Component<ICustomerGridDataViewProps, ICustomerGridDataViewState> {
    private _columnCount: number = 0;

    constructor(props: ICustomerGridDataViewProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { customers } = this.props;

        return <div className="ms-Grid">
            <List items={customers} onRenderCell={this.onRenderCell} />
        </div>;
    }

    private onRenderCell = (customer?: ShortCustomerModel, index?: number, isScrolling?: boolean) => {
        if (customer == null) return <>empty</>;

        return <div className="pointer" onClick={() => this.props.onSelect(customer)}>
            <CustomerRowView key={customer.id} customer={customer} />
        </div>;
    }
}