import { DefaultButton, ProgressIndicator, TextField } from '@fluentui/react';
import * as React from 'react';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { OrderModel } from '../../models/orders/OrderModel';
import { EditProductModel } from '../../models/products/ProductModel';
import { OrderService } from '../../services/OrderService';
import { DataUtilities } from '../common/DataUtilities';
import { Utils } from '../common/Utils';

interface IEditOrderProductViewProps {
    item: OrderModel;
    onCompleted: () => void;
}

interface IEditOrderProductViewState {
    model: EditProductModel;
    isSubmitting: boolean;
    showPicture: boolean;
}

export default class EditOrderProductView extends React.Component<IEditOrderProductViewProps, IEditOrderProductViewState> {
    constructor(props: IEditOrderProductViewProps) {
        super(props);

        this.state = {
            model: props.item.product,
            isSubmitting: false,
            showPicture: false
        }
    }

    render() {
        const { isSubmitting } = this.state;

        return <div>
            {this.productForm()}

            {!isSubmitting && <div className="pt-15 right">
                <DefaultButton onClick={this.submit}>Save Change</DefaultButton>
            </div>}

            {isSubmitting && <div className="pt-15">
                <ProgressIndicator label="Submitting" description="" />
            </div>}
        </div>;
    }

    private productForm = () => {
        const { model } = this.state;

        return <div>
            <div>
                <TextField label="Name" value={model.name}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("name", newValue)} />
            </div>

            <div className="pt-10">
                <TextField label="Description" value={model.description}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("description", newValue)} />
            </div>

            <div className="ms-Grid pt-10">
                <div className={Utils.createPanelStyle(6)} style={{ paddingLeft: 0 }}>
                    <TextField label="Weight" value={model.weight.toString()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("weight", newValue)} />
                </div>
                <div className={Utils.createPanelStyle(6)} style={{ paddingRight: 0 }}>
                    <TextField label="Dimension" value={model.dimension}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("dimension", newValue)} />
                </div>
            </div>

            <div className="pt-10">
                <TextField label="Link" value={model.link} multiline rows={2}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("link", newValue)} />
            </div>

            <div className="pt-10">
                <TextField label="Note" value={model.note} multiline rows={2}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("note", newValue)} />
            </div>
        </div>;
    }

    private submit = () => {
        const { item } = this.props;
        const { model } = this.state;
        let request = OrderService.editProduct(item.id, model);

        this.setState({ isSubmitting: true });
        DataUtilities.onRequest(request, this.onResult);
    }

    private onResult = (result: IDataResponseResult) => {
        const { onCompleted } = this.props;

        if (result.status === ResponseStatus.success && onCompleted) {
            onCompleted();
        }

        this.setState({ isSubmitting: false });
    }

    private onValueChange = (name: string, value: any) => {
        let model = this.state.model as any;
        model[name] = value;
        this.setState({ model: model });
    }
}