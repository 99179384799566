export interface PagedRow { }

export enum SortDirection {
    ascending = 0, descending = 1
}

export class SortModel {
    key: number = 0;
    name: string = "";
    text: string = "";
    direction: SortDirection = SortDirection.ascending;
}

export class PageFilterModel {
    public startDate?: Date;
    public endDate?: Date;
    public search: string | undefined;
    public pageNumber: number = 0;
    public pageSize: number = 0;
    public sortModel: SortModel = new SortModel();
    public pagingDisabled: boolean = false;
}

export class PagedListModel<T> {
    public items: T[] = [];
    public totalCount: number = 0;
}