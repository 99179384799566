import { ActionButton, DocumentCard, Icon } from '@fluentui/react';
import * as React from 'react';
import { Utils } from '../common/Utils';
import { PhotoCollageModel } from '../../models/photoCollages/PhotoCollageModel';
import { PhotoShapeManager } from '../photoCollages/PhotoShapeManager';
import PhotoCollageView from '../photoCollages/PhotoCollageView';

interface IAdvertisementItemViewProps {
    advertisement: PhotoCollageModel;
    onEdit: (advertisement: PhotoCollageModel) => void;
    onDetail: (advertisement: PhotoCollageModel) => void;
    onCompleted: () => void;
    photoManager: PhotoShapeManager;
}

interface IAdvertisementItemViewState { }

export default class AdvertisementItemView
    extends React.Component<IAdvertisementItemViewProps, IAdvertisementItemViewState> {
    private _width6 = Utils.createPanelStyle(6);
    private _width4 = Utils.createPanelStyle(4);
    private _width8 = Utils.createPanelStyle(8);

    constructor(props: IAdvertisementItemViewProps) {
        super(props);

        this.state = {
            showDetail: false
        };
    }

    render() {
        const { advertisement, photoManager } = this.props;

        return <DocumentCard
            styles={{ root: { maxWidth: "100%", paddingLeft: 10, paddingRight: 10, border: "white !important" } }}>
            <div className="center">
                {advertisement.pictures.length > 0 && <PhotoCollageView collage={advertisement} photoManager={photoManager} />}
                {advertisement.pictures.length === 0 && <div><Icon iconName="" style={{ width: 200, height: 200 }} /></div>}
            </div>

            <div className="font-bold">{advertisement.brand}</div>
            <div className="pt-10">{advertisement.title}</div>
            <div className="pt-10">{advertisement.description}</div>
            <div className="pt-10">{this.actionRow()}</div>
        </DocumentCard>
    }

    private actionRow = () => {
        const { advertisement, onEdit, onDetail } = this.props;

        return <div className="disable-action-button ms-Grid ph-5 primary-background-color">
            <div className="ms-Grid-row">
                <div className={this._width6}>
                    <ActionButton
                        iconProps={{ iconName: "Edit" }}
                        styles={{ icon: { color: "black" } }}
                        onClick={() => onEdit(advertisement)}>Edit</ActionButton>
                </div>
                <div className={this._width6 + " right"}>
                    <ActionButton
                        iconProps={{ iconName: "CheckList" }}
                        styles={{ icon: { color: "black" } }}
                        onClick={() => onDetail(advertisement)}>Detail</ActionButton>
                </div>
            </div>
        </div>;
    }
}