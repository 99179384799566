import React from "react";
import {
    PhotoCollageDisplayCount, PhotoCollageLayout, PhotoCollageModel, PhotoSize
} from "../../models/photoCollages/PhotoCollageModel";
import FivePhotoView from "./FivePhotoView";
import FourPhotoView from "./FourPhotoView";
import { PhotoShapeManager } from "./PhotoShapeManager";
import ThreePhotoView from "./ThreePhotoView";
import TwoPhotoView from "./TwoPhotoView";

interface IPhotoCollageViewProps {
    collage: PhotoCollageModel;
    photoManager: PhotoShapeManager;
    onSelect?: (count: PhotoCollageDisplayCount, layout: PhotoCollageLayout) => void;
}

interface IPhotoCollageViewState { }

export default class PhotoCollageView
    extends React.Component<IPhotoCollageViewProps, IPhotoCollageViewState> {
    constructor(props: IPhotoCollageViewProps) {
        super(props);

        this.state = {};
    }

    public render() {
        const { collage, photoManager, onSelect } = this.props;

        const count = this.getDisplayCount();
        const layout = this.getLayout(count);

        return <div className="p-20" style={{ minHeight: 300 }}
            onClick={() => { if (onSelect) { onSelect(count, layout) } }}>

            {count === PhotoCollageDisplayCount.one && <>
                {photoManager.getRectangle(
                    collage.pictures[0],
                    PhotoSize.extraFull,
                    PhotoSize.extraFull,
                    true
                )}
            </>}
            {count === PhotoCollageDisplayCount.two && <>
                <TwoPhotoView photos={collage.pictures} photoManager={photoManager} layout={layout} />
            </>}
            {count === PhotoCollageDisplayCount.three && <>
                <ThreePhotoView photos={collage.pictures} photoManager={photoManager} layout={layout} />
            </>}
            {count === PhotoCollageDisplayCount.four && <>
                <FourPhotoView photos={collage.pictures} photoManager={photoManager} layout={layout} />
            </>}
            {count === PhotoCollageDisplayCount.five && <>
                <FivePhotoView photos={collage.pictures} photoManager={photoManager} layout={layout} />
            </>}
        </div>;
    }

    private getDisplayCount = (): PhotoCollageDisplayCount => {
        const { collage } = this.props;

        if (collage.displayCount && this.isvalidCount()) {
            return collage.displayCount;
        }

        const count = collage.pictures.length;

        if (count === 1) return PhotoCollageDisplayCount.one;
        else if (count === 2) return PhotoCollageDisplayCount.two;
        else if (count === 3) return PhotoCollageDisplayCount.three;
        else if (count === 4) return PhotoCollageDisplayCount.four;

        return PhotoCollageDisplayCount.five;
    }

    private isvalidCount = (): boolean => {
        const { collage } = this.props;

        if (!collage.displayCount) return false;

        let intCount = collage.displayCount as number;

        return collage.pictures.length >= intCount;
    }

    private getLayout = (count: PhotoCollageDisplayCount): PhotoCollageLayout => {
        const { collage } = this.props;

        if (collage.layout) return collage.layout;

        if (count === PhotoCollageDisplayCount.one) return PhotoCollageLayout.horizontalHalf;
        else if (count === PhotoCollageDisplayCount.two) return PhotoCollageLayout.horizontalHalf;
        else if (count === PhotoCollageDisplayCount.three) return PhotoCollageLayout.horizontalTwoThird;
        else if (count === PhotoCollageDisplayCount.four) return PhotoCollageLayout.verticalTwoThird;
        else if (count === PhotoCollageDisplayCount.five) return PhotoCollageLayout.verticalTwoThird;

        return PhotoCollageLayout.allSquare;
    }
}