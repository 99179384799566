import { WarehouseModel } from "../products/WarehouseModel";

export class UserRolesInfo {
    userName: string = "";
    displayName: string = "";
    providerKey: string = "";
    accountImageUrl?: string;
    userId: string = "";
    customerId: number = 0;
    isAdministrator: boolean = false;
    isGeneralManager: boolean = false;
    isDataEntry: boolean = false;
    isSalesSupervisor: boolean = false;
    isSalesperson: boolean = false;
    isNotEmployee: boolean = false;
    isLocal: boolean = false;
    isRestricted: boolean = false;
    isMobile: boolean = false;
    isAppleDevice: boolean = false;
    defaultWarehouse: WarehouseModel = new WarehouseModel();
    warehouses: WarehouseModel[] = [];
    paymentAccounts: PaymentAccountModel[] = [];
}

export class PaymentAccountModel {
    id: number = 0;
    name: string = "";
}