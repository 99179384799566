import React from 'react';
import {
    IDropdownOption, IComboBoxOption, SelectableOptionMenuItemType, MessageBarType
} from "@fluentui/react";
import { IDataResponseResult, ResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { BooleanOption, DropdownItem } from '../../models/others/OtherTypes';
import { RecordInfo } from '../../models/main/RecordInfo';
import { Utils } from './Utils';
import { useNavigate } from 'react-router-dom';
import { Authentication } from './Authentication';
import { FileCategory, FileType } from '../../models/products/PictureModel';
import { WarehouseModel } from '../../models/products/WarehouseModel';

export type dropdownItemCallback = (list: DropdownItem[]) => void;
export type dropdownOptionCallback = (list: IDropdownOption[]) => void;
export type comboOptionCallback = (list: IComboBoxOption[]) => void;
export type onResponseResultAction = (result: IDataResponseResult) => void;

export class DataUtilities {
    public static PICTURE_COLUMN_WIDTH = 350;
    public static PICTURE_COLUMN_WIDTH_MOBILE = 190;
    public static PANEL_WIDTH = "450px";
    public static DEFAULT_GRID_PAGE_SIZE = 50;

    public static _comboBoxOptions: any = {
        "header": SelectableOptionMenuItemType.Header,
        "divider": SelectableOptionMenuItemType.Divider,
        "normal": SelectableOptionMenuItemType.Normal
    };

    public static getBooleanOptions2(includeBlank = true) {
        let list = [];

        if (includeBlank) list.push({ key: null, text: "" });

        list.push({ key: BooleanOption.no, text: "No" });
        list.push({ key: BooleanOption.yes, text: "Yes" });

        return list;
    }

    public static getBooleanOptions() {
        return [
            { key: null, text: "All" },
            { key: BooleanOption.no, text: "Closed Only" },
            { key: BooleanOption.yes, text: "Open Only" }
        ];
    }

    public static shouldConfirmLoginRequest() {
        const lastDate = Authentication.getLastLoginRequest();
        const elapsed = Date.now() - lastDate;
        //1 minutes = 60 x 1000
        return elapsed >= 60000;
    }

    public static onRequest2(request: Promise<any>, onResult: onResponseResultAction,
        showNotification: boolean = true, showLoginConfirm: boolean = true) {
        request.then((response: any) => this.onSubmitSuccessful(response, onResult, showNotification));
        request.catch((error) => {
        });
    }

    public static onRequest(request: JQueryPromise<any>, onResult: onResponseResultAction,
        showNotification: boolean = true, showLoginConfirm: boolean = true) {
        request.done((response: any) => this.onSubmitSuccessful(response, onResult, showNotification));
        request.fail((jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
            //clear user info
            //if (jqXHR && jqXHR.status == 401) {
            //    Authentication.clearUserRoles();
            //}

            if (jqXHR && jqXHR.status === 401 && showLoginConfirm) {
                if (this.shouldConfirmLoginRequest()) {
                    Authentication.setLastLoginRequest();

                    if (window.confirm("Login is required. Do you want to login now?")) {
                        const redirect = useNavigate();
                        redirect("/login");
                        return;
                    }
                }
            } else {
                if (jqXHR.status === 403) textStatus = "Your cannot access forbidden data.";

                this.onSubmitFail(jqXHR, textStatus, errorThrown, onResult, showNotification);
            }
        });
    }

    private static onSubmitSuccessful(response: any, onResult: onResponseResultAction,
        showNotification: boolean) {
        const responseResult = response as ResponseResult;

        let result: IDataResponseResult = {
            data: response,
            status: ResponseStatus.success,
            errorMessages: null,
            summaryText: "",
            summaryType: MessageBarType.success
        };

        if (responseResult !== null) {
            result.data = responseResult.returnedValue;
            result.status = responseResult.status;
            result.summaryText = responseResult.description;
            result.summaryType = responseResult.status === ResponseStatus.success ?
                MessageBarType.success :
                MessageBarType.warning;
        }

        if (onResult) onResult(result);

        if (showNotification && result.summaryText) {
            Utils.sendNotification("Message: " + result.summaryText, result.summaryType?.toString());
        }
    }

    private static onSubmitFail(jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string,
        onResult: onResponseResultAction, showNotification: boolean) {
        let responseResult = jqXHR.responseJSON as ResponseResult;

        let result: IDataResponseResult = {
            status: ResponseStatus.fail,
            summaryType: MessageBarType.error,
            summaryText: textStatus,
            errorMessages: null
        };

        if (!Utils.isNull(responseResult)) {
            result.data = responseResult.returnedValue;
            result.summaryText = responseResult.description;
            result.errorMessages = responseResult.invalidData;
        }

        if (onResult) onResult(result);

        if (showNotification && result.summaryText) {
            Utils.sendNotification("Error: " + result.summaryText, result.summaryType?.toString());
        }
    }

    public static getOptionText(options: IDropdownOption[], value: any) {
        for (let i = 0; i < options.length; i++) {
            if (options[i].key === value) return options[i].text;
        }
    }

    public static getNumberOptions(includeZero = false): IComboBoxOption[] {
        const list = [];

        if (includeZero) {
            list.push({ key: 0, text: "0" });
            list.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
        }

        for (let i = 1; i <= 20; i++)
            list.push({ key: i, text: i.toString() });

        return list;
    }

    public static getLastDaysOptions(): IComboBoxOption[] {
        return [
            { key: 0, text: "All Transactions" },
            { key: 30, text: "Last 30 days" },
            { key: 60, text: "Last 60 days" },
            { key: 90, text: "Last 90 days" },
            { key: 180, text: "Last 180 days" },
            { key: 360, text: "Last 360 days" }
        ];
    }

    public static printRecordInfo(record: RecordInfo, labelClassName: string, valueClassName: string) {
        if (!Utils.isNull(record)) {
            const activeClass = record.active ? "active" : "inactive";
            const deletedClass = record.deleted ? "inactive" : "active";

            return <>
                <div className="font-bold pb-10">Record Info</div>
                <div className="ms-Grid-row pb-5">
                    <div className={labelClassName}>Created:</div>
                    <div className={valueClassName}>{Utils.formatLongDate(record.created)}</div>
                </div>
                <div className="ms-Grid-row pb-5">
                    <div className={labelClassName}>Created By:</div>
                    <div className={valueClassName}>
                        <span>{record.createdBy}</span>
                    </div>
                </div>
                <div className="ms-Grid-row pb-5">
                    <div className={labelClassName}>Last Modified:</div>
                    <div className={valueClassName}>{Utils.formatLongDate(record.lastModified)}</div>
                </div>
                <div className="ms-Grid-row pb-5">
                    <div className={labelClassName}>Last Modified By:</div>
                    <div className={valueClassName}>{record.lastModifiedBy}</div>
                </div>
                <div className="ms-Grid-row pb-5">
                    <div className={labelClassName}>Active:</div>
                    <div className={activeClass + " " + valueClassName}>
                        {record.active ? "Active" : "Inactive"}
                    </div>
                </div>
                <div className="ms-Grid-row pb-5">
                    <div className={labelClassName}>Deleted:</div>
                    <div className={deletedClass + " " + valueClassName}>
                        {record.deleted ? "Deleted" : "No"}
                    </div>
                </div>
            </>;
        }
    }

    public static getSelectedWarehouse(warehouses: WarehouseModel[]) {
        const selectedId = Authentication.getSelectedWarehouseId();

        if (Utils.isArrayNullOrEmpty(warehouses)) {
            Authentication.setWarehouse(0);
            return undefined;
        } else if (selectedId) {
            const intSelectedId = selectedId;
            const selectedWarehouse = warehouses.find((warehouse: WarehouseModel) => warehouse.id === intSelectedId);

            if (!Utils.isNull(selectedWarehouse)) {
                return selectedWarehouse;
            }
        }

        const selectedWarehouse = warehouses[0];
        Authentication.setWarehouse(selectedWarehouse.id);
        return selectedWarehouse;
    }

    public static getFileCategoryOptions(): IDropdownOption[] {
        return [
            { key: FileCategory.feature, text: FileCategory[FileCategory.feature] },
            { key: FileCategory.normal, text: FileCategory[FileCategory.normal] },
            { key: FileCategory.original, text: FileCategory[FileCategory.original] }
        ];
    }

    public static getFileTypeOptions(): IDropdownOption[] {
        return [
            { key: FileType.image, text: FileType[FileType.image] },
            { key: FileType.video, text: FileType[FileType.video] },
            { key: FileType.others, text: FileType[FileType.others] }
        ];
    }
}